html body {
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.online-circle {
  height: 10px;
  width: 10px;
  background-color: greenyellow;
  border-radius: 50%;
  display: inline-block;
}
.time-ago {
  color: #666;
  font-size: 10.5px;
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 950px) {
  #add-question-button-text {
    display: none !important;
  }
  #add-question-button-plus {
    display: default !important;
  }
}
@media only screen and (max-width: 767px) {
  #grid-radio {
    display: none !important;
  }
}
